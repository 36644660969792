nav {
  /* width: 100%;
  position: sticky;
  z-index: 10;
  top: 0;
  padding: 1.2em 0;
  color: rgb(212, 95, 95);
  background-color: var(--darkAccent);
  max-height: 4em; */
  /* border: 2px solid red; */

  text-decoration: none;
  color: var(--secondary-color);
  font-size: 14px;
}

nav li {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
}

nav span {
  font-size: 18px;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.2s ease;
  transition-delay: 0.25s;
  margin: 1em;
  padding-bottom: 0.3em;
  /* border: 1px solid black; */
}

nav span:hover {
  cursor: pointer;
  border-bottom: 1px;
  border-bottom-color: var(--primary-color);
}

nav span:hover i {
  color: rgb(71, 62, 9);
}

nav span.activeClass i {
  color: gold;
}

nav span.activeClass {
  border-bottom-color: var(--lightAccent);
}

.logo-image {
  width: 176px;
  height: 46px;
  margin-top: -6px;
}
